/* Login Page Layout */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f4f8; /* Slightly lighter and smoother gray background */
  padding: 0 20px;
}

.login-container {
  width: 100%;
  max-width: 450px;
  background: #ffffff;
  border-radius: 12px; /* Softer rounded corners */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* More depth in shadow */
  padding: 40px;
  text-align: center;
  transition: box-shadow 0.3s ease;
}

.login-container:hover {
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.15); /* Hover effect for more depth */
}

/* Login Title */
.login-title {
  margin-bottom: 30px;
  font-size: 28px; /* Larger, bolder font */
  font-weight: 600; /* Stronger emphasis */
  color: #333;
  letter-spacing: 1px;
}

/* Form Layout */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Increased gap for cleaner spacing */
}

/* Input Group */
.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-group label {
  margin-bottom: 8px;
  font-size: 15px;
  color: #555;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.input-group input {
  width: 100%;
  padding: 12px 15px; /* Increased padding for better touch target */
  border: 1px solid #ccc;
  border-radius: 6px; /* Slightly larger border radius for modern feel */
  font-size: 16px; /* Slightly larger font for readability */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input-group input:focus {
  border-color: #0073e6; /* Focus color for input field */
  box-shadow: 0 0 8px rgba(0, 115, 230, 0.3); /* Subtle focus shadow */
  outline: none; /* Remove default outline */
}

/* Buttons */
.login-button {
  background-color: #0073e6; /* AWS-like blue */
  color: #ffffff;
  border: none;
  padding: 12px 15px;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 20px;
}

.login-button:hover {
  background-color: #005bb5; /* Darker blue for hover */
  transform: translateY(-2px); /* Slight lift effect */
}

/* Google Button */
.google-login {
  background-color: #4285f4; /* Google blue */
  padding: 12px 15px;
  margin-top: 20px;
  border-radius: 6px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.google-login:hover {
  background-color: #357ae8; /* Darker Google blue */
  transform: translateY(-2px); /* Slight lift effect */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .login-container {
    padding: 20px;
  }

  .login-title {
    font-size: 24px;
  }

  .login-button,
  .google-login {
    font-size: 15px;
    padding: 10px;
  }
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
