/* Layout.css */

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent body from scrolling */
}

.horizontal-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px; /* Height of the horizontal navbar */
  z-index: 1000; /* Ensure it's above the content */
  background-color: #f8f9fa; /* Adjust based on your design */
}

.main-content {
  display: flex;
  flex: 1;
  margin-top: 50px; /* Space for horizontal navbar */
  overflow: hidden; /* Prevent content from overflowing */
}

.vertical-navbar {
  position: fixed;
  top: 50px; /* Position below horizontal navbar */
  left: 0;
  width: 250px; /* Default width */
  height: calc(100vh - 50px); /* Full height minus horizontal navbar */
  background-color: #232f3e; /* Adjust based on your design */
  transition: width 0.3s ease;
  z-index: 1000;
}

.vertical-navbar.closed {
  width: 60px; /* Narrower width when closed */
}

.content-container {
  margin-left: 250px; /* Default space for open vertical navbar */
  padding: 10px;
  flex: 1;
  /* height: calc(100vh - 50px); */
  overflow-y: auto; /* Enable vertical scrolling */
  transition: margin-left 0.3s ease;
}

.content-container.scrolled {
  /* Additional styles if needed */
}
