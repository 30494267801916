/* Container and layout */
.view-users-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f3f4f6;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.page-title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
}

/* Search bar */
.search-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-bar .form-control {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.btn-clear {
  margin-left: 10px;
  background-color: #d9534f;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-clear:hover {
  background-color: #c9302c;
}

/* Table styling */
.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
}

.table thead {
  background-color: #0073e6;
  color: #fff;
}

.table th,
.table td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #e0e0e0;
  font-size: 14px;
}

.table tbody tr:hover {
  background-color: #f1f1f1;
}

/* Button styling */
.btn-view {
  background-color: #0073e6;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.btn-view:hover {
  background-color: #005bb5;
}

/* Signature image styling */
.signature-image {
  width: 150px;
  height: auto;
  border-radius: 4px;
}

/* Text center for no data */
.text-center {
  text-align: center;
  font-size: 16px;
  color: #777;
}

/* Loader container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

/* Loader wrapper */
.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* AWS-style loader */
.loader-circle {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #0073e6;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

.loader-wrapper p {
  font-size: 16px;
  color: #0073e6;
  font-weight: 500;
}

/* Keyframes for spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Unauthorized message */
.unauthorized-message {
  text-align: center;
  font-size: 24px;
  color: #d9534f;
  font-weight: bold;
}
