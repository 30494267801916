/* VerticalNavbar.css */

.vertical-navbar {
  width: 250px;
  height: 100vh;
  background-color: #232f3e; /* AWS dark blue */
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;
  z-index: 1100; /* Ensure it stays above other elements */
}

.vertical-navbar.closed {
  width: 60px; /* Narrower width when closed */
}

.navbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* background-color: #1e4e8c; */
  position: relative;
}

.navbar-logo img {
  max-width: 100%;
  display: block;
  transition: opacity 0.3s ease;
}

.vertical-navbar.closed .navbar-logo img {
  opacity: 0; /* Hide logo when navbar is closed */
}

.navbar-toggle {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem; /* Size of the icon */
  cursor: pointer;
  padding: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1101; /* Ensure it stays above other elements */
}

.navbar-toggle-1 {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem; /* Size of the icon */
  cursor: pointer;
  padding: 5px;
}

.vertical-navbar.closed .navbar-toggle {
  font-size: 2rem; /* Larger icon when closed */
}

.navbar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  padding-top: 0px; /* Pushes the menu below the header */
}

.navbar-menu li {
  width: 100%;
}

.navbar-menu li a {
  display: block;
  padding: 15px 20px;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.navbar-menu li a:hover,
.navbar-menu li a.active {
  background-color: #1e4e8c;
}

.navbar-menu li a.active {
  background-color: #ff9900; /* AWS orange */
}
