/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  z-index: 1050;
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.btn-close {
  background-color: #0073e6;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  /* move to the right */
  position: absolute;
  right: 10px;
}

.btn-close:hover {
  background-color: #005bb5;
}
