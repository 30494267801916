.aero-navbar {
  background-color: #232f3e; /* Aeroqube dark blue */
  color: white;
  padding: 3px 10px; /* Further reduced padding */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aero-navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.aero-navbar-left {
  display: flex;
  align-items: center;
}

.aero-navbar-brand {
  color: white;
  text-decoration: none;
}

.aero-brand {
  display: flex;
  flex-direction: column; /* Keep vertical stacking */
  font-weight: bold;
  font-size: 1.2rem; /* Reduced font size further */
  color: #ff9900; /* Aeroqube orange */
}

.aero-company-name {
  font-size: 0.6rem; /* Reduced font size further */
  color: #ffffff;
  font-weight: normal;
  margin-top: 1px; /* Minimal margin */
}

.aero-nav-link {
  color: white;
  margin-right: 10px; /* Reduced margin */
  font-size: 0.85rem; /* Reduced font size */
  text-decoration: none;
  padding: 3px 7px; /* Reduced padding */
  transition: background-color 0.3s;
  /* make bold */
  font-weight: bold;
  font-size: 1.15rem;
}

.aero-nav-link:hover,
.aero-nav-link.active {
  background-color: #37475a; /* Aeroqube dark hover */
  border-radius: 4px;
}

.aero-navbar-right {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.aero-navbar-user {
  margin-right: 10px; /* Reduced margin */
  font-weight: bold;
}

.aero-navbar-btn {
  background-color: #ff9900; /* Aeroqube orange */
  border: none;
  color: white;
  padding: 3px 8px; /* Further reduced padding */
  margin-right: 8px; /* Reduced margin */
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem; /* Reduced font size */
  transition: background-color 0.3s;
}

.aero-navbar-btn:hover {
  background-color: #ffae42; /* Slightly lighter orange */
}

.aero-logout-btn {
  background-color: #d9534f; /* Bootstrap danger color */
}

.aero-logout-btn:hover {
  background-color: #c9302c; /* Darker red on hover */
}
