.submit-cheque-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
  background-color: #f0f2f5;
}

.form-container {
  max-width: 500px;
  width: 100%;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  text-align: center;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #333;
}

.input-group input[type="text"],
.input-group input[type="file"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.submit-button {
  width: 100%;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button:disabled {
  background-color: #b0c4de;
  cursor: not-allowed;
}

.message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
}

.message.success {
  color: #28a745;
  border: 1px solid #28a745;
  background-color: #e9fbe9;
}

.message.error {
  color: #dc3545;
  border: 1px solid #dc3545;
  background-color: #f8d7da;
}

/* Loader CSS */
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 0.8s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive */
@media (max-width: 600px) {
  .form-container {
    padding: 20px;
  }

  .submit-button {
    font-size: 14px;
  }

  .input-group input {
    padding: 10px;
  }
}

/* Button base styling */
.submit-button,
.view-button {
  width: 100%;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Hover effect */
.submit-button:hover,
.view-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px); /* Slight lift on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

/* Active state (on click) */
.submit-button:active,
.view-button:active {
  background-color: #00408a;
  transform: translateY(0); /* Reset the lift */
  box-shadow: none; /* Remove shadow on click */
}

/* Disabled button state */
.submit-button:disabled {
  background-color: #b0c4de;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Loader button styling */
.submit-button .loader {
  margin-left: 10px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.6s linear infinite;
}

/* View button styling */
.view-button {
  margin-top: 15px;
  background-color: #28a745; /* Green color for "View Cheque" */
}

.view-button:hover {
  background-color: #218838;
}

/* Responsive */
@media (max-width: 600px) {
  .submit-button,
  .view-button {
    font-size: 14px;
    padding: 10px;
  }

  .submit-button .loader {
    width: 18px;
    height: 18px;
  }
}
