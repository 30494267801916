/* Container and layout */
.view-cheque-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f3f4f6;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.page-title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
}

/* Table styling */
.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
}

.table th,
.table td {
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  text-align: left;
  font-size: 14px;
}

.table th {
  background-color: #f5f7f9;
  color: #333;
  font-weight: bold;
}

.table td {
  color: #555;
}

/* Button styling */
.view-button {
  background-color: #0073e6;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.view-button:hover {
  background-color: #005bb5;
}

/* Text center for no data */
.text-center {
  text-align: center;
  font-size: 16px;
  color: #777;
}

/* Unauthorized message */
.unauthorized-message {
  text-align: center;
  color: #dc3545;
  font-size: 24px;
  font-weight: bold;
}

/* Loader container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

/* Loader wrapper */
.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* AWS-style loader */
.loader-circle {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #0073e6; /* AWS blue color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

.loader-wrapper p {
  font-size: 16px;
  color: #0073e6;
  font-weight: 500;
}

/* Keyframes for spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Search bar styling */
.search-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  gap: 10px;
}

/* Search input */
.search-bar input {
  padding: 8px;
  width: 250px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Action buttons (Clear and Search Today) */
.action-button {
  background-color: #0073e6;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.action-button:hover {
  background-color: #005bb5;
}

/* Ensure styling remains consistent */
.table th,
.table td {
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  text-align: left;
  font-size: 14px;
}
