/* CreateCustomer.css */

.customer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f7f9;
  padding: 0 20px;
}

.form-container {
  width: 100%;
  max-width: 500px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.form-title {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  text-align: center;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input-group label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
}

.input-group input[type="text"],
.input-group input[type="file"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.submit-button {
  width: 100%;
  background-color: #0073e6;
  color: #fff;
  border: none;
  padding: 12px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #005bb5;
}

.submit-button:disabled {
  background-color: #b0c4de;
  cursor: not-allowed;
}

/* Message styles */
.message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
}

.message.success {
  color: #28a745;
  border: 1px solid #28a745;
  background-color: #e9fbe9;
}

.message.error {
  color: #dc3545;
  border: 1px solid #dc3545;
  background-color: #f8d7da;
}

/* Loader */
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
