/* Container and layout */
.view-cheque-container {
  max-width: 900px;
  margin: 60px auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.view-cheque-container:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

/* Page title */
.page-title {
  font-size: 32px;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
  letter-spacing: 0.5px;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
}

/* Detail item */
.detail-item {
  margin-bottom: 25px;
  font-size: 18px;
  color: #555;
  font-weight: 500;
  background-color: #f9fafb;
  padding: 15px;
  border-radius: 8px;
}

/* Cheque images */
.cheque-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin-bottom: 20px; /* Added spacing between images */
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.cheque-image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Button styling */
.btn-back,
.btn-refresh,
.btn-download {
  background-color: #0073e6;
  color: white;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 10px; /* Added gap between buttons */
  margin: 10px;
}
.btn-process {
  background-color: orange;
  color: white;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin: 10px;
}
.btn-delete {
  background-color: red;
  color: white;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin: 10px;
}
.btn-back + .btn-refresh {
  margin-left: 15px; /* Spacing between Back and Refresh buttons */
}

.btn-back:hover,
.btn-refresh:hover,
.btn-download:hover {
  background-color: #005bb5;
  transform: translateY(-2px);
}
.btn-delete:hover {
  background-color: darkred;
  transform: translateY(-2px);
}
.btn-process:hover {
  background-color: darkorange;
  transform: translateY(-2px);
}

/* Loader container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

/* Loader wrapper */
.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader-circle {
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #0073e6;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

.loader-wrapper p {
  font-size: 18px;
  color: #0073e6;
  font-weight: 600;
}

/* Keyframes for spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Unauthorized message */
.unauthorized-message {
  text-align: center;
  font-size: 24px;
  color: #d9534f;
  font-weight: bold;
}

/* Button group */
.button-group {
  margin-top: 40px;
  text-align: center;
}

@media (max-width: 768px) {
  .btn-back,
  .btn-refresh,
  .btn-download {
    width: 100%;
    margin-top: 10px;
  }
}

/* In ViewCheque.css */

.status-banner {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
}

.status-good {
  background-color: #d4edda; /* Light green */
  color: #155724; /* Dark green */
  border: 1px solid #c3e6cb;
}

.status-bad {
  background-color: #f8d7da; /* Light red */
  color: #721c24; /* Dark red */
  border: 1px solid #f5c6cb;
}

.status-incomplete {
  background-color: #d6d8d9; /* Light grey */
  color: #383d41; /* Dark grey */
  border: 1px solid #c6c8ca;
}
