/* Container and layout */
.customer-details-container {
  max-width: 900px;
  margin: 60px auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Soft shadow for premium look */
  transition: box-shadow 0.3s ease-in-out;
}

.customer-details-container:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15); /* Hover effect for depth */
}

/* Page title */
.page-title {
  font-size: 32px;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
  letter-spacing: 0.5px;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
}

/* Details wrapper */
.details-wrapper {
  margin-bottom: 40px;
}

/* Detail item */
.detail-item {
  margin-bottom: 25px;
  font-size: 18px;
  color: #555;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9fafb;
  padding: 15px;
  border-radius: 8px;
}

/* Signature image styling */
.signature-image {
  width: 180px;
  height: auto;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.signature-image:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Action buttons */
.action-buttons {
  margin-top: 50px;
  text-align: center;
}

.btn-back {
  background-color: #0073e6;
  color: white;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-back:hover {
  background-color: #005bb5;
  transform: translateY(-2px);
}

/* Loader container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

/* Loader wrapper */
.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* AWS-style loader */
.loader-circle {
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #0073e6;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

.loader-wrapper p {
  font-size: 18px;
  color: #0073e6;
  font-weight: 600;
}

/* Keyframes for spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Unauthorized message */
.unauthorized-message {
  text-align: center;
  font-size: 24px;
  color: #d9534f;
  font-weight: bold;
}
