.aws-table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 30px;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(27, 31, 35, 0.12);
  background: #fff;
}

.aws-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Amazon Ember", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.aws-th,
.aws-td {
  padding: 12px 16px;
  border-bottom: 1px solid #e1e4e8;
  text-align: left;
}

.aws-th {
  background-color: #f3f4f6;
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.aws-tr:hover {
  background-color: #f1f5f9;
}

.aws-name {
  font-size: 14px;
  text-transform: capitalize;
  color: #24292e;
}

.aws-image {
  text-align: center;
}

/* Increase the size of the preview image */
.aws-image-preview {
  max-width: 200px; /* increased from 120px */
  width: 100%;
  height: auto;
  border: 1px solid #d1d5da;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.aws-image-preview:hover {
  transform: scale(1.05);
}
