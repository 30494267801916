.front-page-container {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
}

.header {
  background-color: #f4f4f4;
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.header h1 {
  margin: 0;
}

.nav {
  margin-top: 10px;
}

.nav a {
  margin: 0 10px;
  text-decoration: none;
  color: #007bff;
}

.nav a:hover {
  text-decoration: underline;
}

.main-content {
  margin: 20px;
}

.intro h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.intro p {
  font-size: 18px;
}

.intro ul {
  list-style-type: disc;
  padding-left: 20px;
}

.footer {
  background-color: #f4f4f4;
  padding: 10px;
  text-align: center;
  border-top: 1px solid #ddd;
  margin-top: 20px;
}

.footer a {
  color: #007bff;
}

.footer a:hover {
  text-decoration: underline;
}
